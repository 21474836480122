.App {
  text-align: center;
  font-family: "Raleway";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.main {
  width: 100%;
  height: 90vh;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
}
.login {
  display: flex;
  flex-direction: column;
  width: 30%;
  background-color: white;
  padding: 1rem;
  margin-left: 35%;
  justify-content: center;
  border-radius: 0.5rem;
  box-shadow: #000;
}

* {
  box-sizing: border-box;
}

/* Add padding to containers */
.container {
  padding: 1px;
}

/* Full-width input fields */
input[type="text"],
input[type="password"],
input[type="number"] {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  display: inline-block;
  border: none;
  background: #f1f1f1;
}

input[type="text"]:focus,
input[type="password"]:focus {
  background-color: #ddd;
  outline: none;
}

/* Overwrite default styles of hr */

/* Set a style for the submit/register button */
.registerbtn {
  background-color: #04aa6d;
  color: white;
  padding: 16px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  opacity: 0.9;
}

.registerbtn:hover {
  opacity: 1;
  color: black;
}

.verify-container {
  background-color: #f7f7f7;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh
}
.verify-title {
  text-align: center;
  color: #000;
  font-size: 2.5rem;
  margin-top: 0.5rem;
  font-weight: 570;
  line-height: 1.2;
}

.verify-subtitle {
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 570;
  line-height: 1.2;
  font-size: 2rem;
  text-align: center;
  color: green;
}

.td-empty {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  border-collapse: collapse;
  width: 21%;
  box-sizing: border-box;
  text-align: left;
  padding: 8px;
}

.td-values {
  font-size: 1rem;
  font-weight: 500 !important;
  line-height: 1.5;
  color: #212529;
  border-collapse: collapse;
  box-sizing: border-box;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #d6eeee;
}

.verify-table {
  text-align: left !important;
  /* width: 40vw; */
}

.subtitle-text {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
    text-align: left;
}

.closing-title {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
  font-size: 1rem;
  line-height: 1.5;
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 1rem;
  text-align: center;
  color: green;
  font-weight: bold;
}
/* Set a grey background color and center the text of the "sign in" section */
.signin {
  background-color: #f1f1f1;
  text-align: center;
}
.signin :hover {
  opacity: 0.9;
  color: black;
}
.input {
  box-shadow: none;
  font-family: "Raleway";
  font-style: normal;
  font-weight: normal;
  box-sizing: border-box;
}
.label {
  font-family: "Raleway";
  font-weight: bolder;
}
.forget {
  width: 100%;
}
.got {
  width: 40%;
  margin-left: 30%;
  background-color: white;
  padding: 7%;
  margin-top: 5%;
  border-radius: 0.5rem;
  height: auto;
}
.root {
  width: 100%;
  height: 100vh;
  display: flex;
}
.sidebar {
  width: 150px;
  background-color: royalblue;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.nav {
  width: 100%;
  font-family: "Raleway";
  font-weight: bolder;
  font-size: 35px;
  color: white;
  text-decoration: none;
}
.sidebar :hover {
  color: black;
}
.new {
  width: 100%;
  height: 100vh;
  background-color: #ddd;
  display: flex;
  flex-direction: column;
}
.form {
  width: 40%;
  padding: 1rem;
  background-color: white;
  margin-left: 30%;
  border-radius: 0.5rem;
  margin-top: 5rem;
}
.text {
  width: 100%;
}
.menu {
  width: 100%;
  /* display: contents; */
  font-weight: normal;
  /* padding:1rem; */
  display: flex;
  font-family: "Raleway";
  font-size: 22px;
  height: 50px;
  background-color: #04aa6d;
}
.sm :hover {
  color: blue;
}
